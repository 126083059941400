import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import style from "./markdown-styles.module.css";

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 1280px;
  margin: 150px auto;
  padding: 0 70px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 0 auto;
    padding: 0 30px;
  }
`;

const TextBox = styled.div`
  align-self: end;

  p {
    margin-top: 20px;
  }
`;

const ImageBox = styled.div`
  max-width: 100%;
  margin-left: -70px;

  img {
    max-width: 100%;
  }
`;

const SectionTwo = ({ title, content, img }) => {
  return (
    <StyledWrapper>
        <ImageBox>
          <img src={img} />
        </ImageBox>
        <TextBox>
            <h3>{title}</h3>
            <ReactMarkdown className={style.reactMarkDown} children={content} />
        </TextBox>
    </StyledWrapper>
  );
};

export default SectionTwo;
